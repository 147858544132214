import "styles/global.scss";
import "styles/textbox.scss";
import "simplebar/dist/simplebar.min.css";
import "./index.css";
import "style-scoped";
import * as serviceWorker from "./serviceWorker";
import { enablePatches, setAutoFreeze } from "immer";

setAutoFreeze(false);
enablePatches();

export const loadRest = () => {
  const s = import("./oldindex");
  s.then((x) => x.default());
};

const loadPlayer = () => {
  const s = import("./player/player");
  s.then((x) => x.PlayJSON());
};

export const preactInfo = {
  container: null as null | HTMLDivElement,
  running: false,
  storyId: undefined as undefined | string,
};

//@ts-ignore

// @ts-ignore
if (window.myVar.indexOf("myVar") !== -1 || window.myVar === undefined) {
  loadRest();
} else {
  // const target = document.getElementById("root");
  // if (target){
  //   target.hidden= true;
  // }
  // if (target) {
  // }
  loadPlayer();
}

// RenderReact();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
